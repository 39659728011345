import React from 'react';
import { Routes, Route } from "react-router-dom";
// @ts-ignore
import Box from '@screentone/core/Box';
// @ts-ignore
import Illustration from '@screentone/core/Illustration';
// @ts-ignore
import Typography from '@screentone/core/Typography';

import '@screentone/core/css/index.css';

import json from './redirects.json';
console.log('json: ', json);

function Home() {
  const { hostname, pathname, search, hash } = window.location;
  // @ts-ignore
  const currentRedirect = json[hostname];
  
  if (!currentRedirect) { 
    return (
      <Box
        style={{
          width: '400px',
          maxWidth: '80vw',
          marginLeft: 'auto', marginRight: 'auto'
        }}
        margin={{ vertical: 'xl' }}
        padding={{ all: 'mlg' }}
      >
        <Illustration style={{ width: '250px', margin: 'var(--st-spacer-md) auto' }}
          name="server-down" />
            <Typography color="lava" variant="header2" align="center">Error redirecting</Typography>
            <Typography variant="bodytext">This tool has moved. We where unable to automatically redirect you.</Typography>
      </Box>
    );  
  }

  const appName = currentRedirect.name;
  const redirectDomain = currentRedirect.redirect;
  const redirectHref = `${currentRedirect.redirect}${pathname}${search}${hash}`;

  setTimeout(() => {
    window.location.replace(redirectHref);
  }, 5000);
  
  return (
    <Box
      style={{
        width: '400px',
        maxWidth: '80vw',
        marginLeft: 'auto', marginRight: 'auto'
      }}
      margin={{ vertical: 'xl' }}
      padding={{ all: 'mlg' }}
    >
      <Illustration
        style={{
          width: '250px',
          // width: '100%',
          margin: 'var(--st-spacer-md) auto',
      }}
        name="empty" />
          <Typography variant="header2" align="center">Redirecting...</Typography>
          <Typography variant="bodytext">{appName} has moved.</Typography>
          <Typography variant="bodytext">You will be redirect to <Typography variant="link" componentEl="a" href={redirectHref} inline>{redirectDomain}</Typography>.</Typography>
    </Box>
  );
}

function App() {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default App;
